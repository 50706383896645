import { api } from "@4cplatform/elements/Api/fetchData"

import {
  LOGIN,
  TWO_FACTOR_AUTH,
  DASHBOARD,
  FORBIDDEN,
  NOT_FOUND,
  PASSWORD_CHANGE_CONFIRMATION
} from "./pages"

const appConfig = {
  API_URL: process.env.REACT_APP_API_URL, // Base url for the api
  API_SCOPE: process.env.REACT_APP_API_SCOPE, // API scope
  TRANSLATION_NAMESPACES: process.env.REACT_APP_TRANSLATION_NAMESPACES
    ? process.env.REACT_APP_TRANSLATION_NAMESPACES.split(", ")
    : [],
  LOGIN_PATH: LOGIN.path, // Route for the login page
  TWOFA_PATH: TWO_FACTOR_AUTH.path, // Route for the 2fa page
  HOME_PATH: DASHBOARD.path, // Where to redirect logged in users
  FORBIDDEN_PATH: FORBIDDEN.path, // Forbidden 403 error page
  NOT_FOUND_PATH: NOT_FOUND.path, // Not Found 404 error page
  PASSWORD_CHANGE_CONFIRMATION_PATH: PASSWORD_CHANGE_CONFIRMATION.path
}

export const pusherConfig = {
  clientKey: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  authorizer: channel => ({
    authorize: (socketId, callback) => {
      api
        .post(`${appConfig.API_URL}/${appConfig.API_SCOPE}/broadcasting/auth`, {
          socket_id: socketId,
          channel_name: channel.name
        })
        .then(response => {
          callback(false, response.data)
        })
        .catch(error => {
          callback(true, error)
        })
    }
  })
}

export default appConfig
