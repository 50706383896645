import React from "react"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { H4 } from "@4cplatform/elements/Typography"

// Components
import AdditionalInfoPanelHeader from "./additionalInfoView.header"
import ProductOption from "./additionalInfoView.productOption"
import { ProductDetailsWrapper } from "./additionalInfoView.styles"

const AdditionalInfoView = ({ isLoading, viewData, productType }) => {
  if (
    viewData?.provider?.name === "AXA Health" &&
    !viewData.options.find(option => option.name === "Hospital List")
  ) {
    const guidedOption = viewData.options.find(option => option.name === "Guided Option")
    if (viewData?.product_name === "Personal Health" && guidedOption.value === "Yes") {
      viewData.options.push({ name: "Hospital List", value: "Guided" })
    } else {
      viewData.options.push({ name: "Hospital List", value: "Fixed List" })
    }
  }

  return (
    <>
      <AdditionalInfoPanelHeader
        viewData={viewData}
        isLoading={isLoading}
        productType={productType}
      />
      <PanelBody>
        <H4 padding="0" margin="0 0 2rem" colour="#fff" isLoading={isLoading}>
          Additional product details
        </H4>

        <ProductDetailsWrapper>
          {viewData?.options?.map(option => (
            <ProductOption
              key={uuid()}
              label={option.name}
              value={option.value}
              isLoading={isLoading}
            />
          ))}
        </ProductDetailsWrapper>
      </PanelBody>
    </>
  )
}

AdditionalInfoView.defaultProps = {
  viewData: {},
  isLoading: false,
  productType: "quote"
}

AdditionalInfoView.propTypes = {
  viewData: PropTypes.object,
  isLoading: PropTypes.bool,
  productType: PropTypes.string
}

export default AdditionalInfoView
