import styled from "styled-components"

// Helpers
export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PaginationWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  justify-content: center;
`
