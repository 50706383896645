import { colours } from "@4cplatform/elements/Helpers"

export const getClientDash = () => [
  {
    title: "Policies",
    links: [
      {
        title: "View Policies",
        route: "/policies"
      }
    ],
    colour: colours.policiesPurple,
    icon: "format-list-checks",
    route: "/policies"
  },
  {
    title: "Quotes",
    links: [
      {
        title: "View Quotes",
        route: "/quotes"
      }
    ],
    colour: colours.quotesBlue,
    icon: "map-marker-path",
    route: "/quotes"
  }
]

export const getClientNav = () => [
  {
    title: "Home",
    colour: colours.policiesPurple,
    icon: "view-dashboard",
    route: "/"
  },
  ...getClientDash()
]
