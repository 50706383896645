import React, { useContext } from "react"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { PoliciesContext } from "./policies.context"

// Components
import { ProductList } from "../../Organisms"

const PolicyAdmin = () => {
  const { setPanelStatus } = useContext(PageContext)
  const {
    data,
    onPolicySelect,
    sorting,
    onSort,
    pagination,
    setPage,
    setPerPage,
    policiesLoading,
    productType
  } = useContext(PoliciesContext)

  return (
    <ProductList
      products={data}
      onDocumentsClick={quote => {
        onPolicySelect(quote)
        setPanelStatus("open")
      }}
      onAddInfoClick={quote => {
        onPolicySelect(quote)
        setPanelStatus("wide")
      }}
      sorting={sorting}
      onSort={onSort}
      pagination={pagination}
      changePage={e => setPage(e)}
      changePerPage={setPerPage}
      isLoading={policiesLoading}
      productType={productType}
    />
  )
}

export default PolicyAdmin
