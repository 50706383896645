import React from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import moment from "moment"
import { nullFunc } from "@4cplatform/elements/Helpers"
import { H3, P } from "@4cplatform/elements/Typography"
import { useTranslations } from "@4cplatform/elements/Translations"
import { usePost } from "@4cplatform/elements/Api/useMutation"
import { addAlert } from "@4cplatform/elements/Alerts"

// Components
import {
  CardWrapper,
  OverlayWrapper,
  OverlayFlex,
  CardHeader,
  CardHeaderColLeft,
  CardHeaderLogo,
  CardHeaderColLeftContent,
  CardHeaderColRight,
  Strong,
  CardHeaderPriceBreakdown,
  CardHeaderPriceBreakdownCol,
  Label,
  CardBody,
  CardBodyCol,
  ButtonSml,
  CardFooter,
  IButton
} from "./productCard.styles"

const ProductCard = ({
  product,
  handleDocumentsClick,
  handleAddInfoClick,
  isLoading,
  productType
}) => {
  const t = useTranslations()

  const hasQuoteExpired = moment().isSameOrAfter(product.expiry_date)
  const flags = get(product, "flags", {})
  const isSwitchSave = get(product, "quote_type", null) === "switch_save"
  const isMoriPlus = get(flags, "VITALITY_MORI_PLUS", false)
  const isQuote = productType === "quote"
  // const handleDocumentClick = () => {
  //   window.open(get(product, isQuote ? "brochure_url" : "demands_and_needs_url", ""), "_blank")
  // }

  // Generate Brochure
  const [generateBrochureDocument, { loading: generateBrochureDocumentLoading }] = usePost({
    endpoint: `/${isQuote ? "quotes" : "policies"}/:reference/generate-document/brochure`,
    params: {
      reference: isQuote ? get(product, "id", "") : get(product, "slug", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      window.open(filePath, "_blank")
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Generate Demands & Neeeds
  const [generateDemandsAndNeedsFormDocument, { loading: generateDemandsAndNeedsDocumentLoading }] =
    usePost({
      endpoint: `/${
        isQuote ? "quotes" : "policies"
      }/:reference/generate-document/demands_and_needs`,
      params: {
        reference: isQuote ? get(product, "id", "") : get(product, "slug", "")
      },
      onCompleted: res => {
        const filePath = get(res, "data.path", "")
        window.open(filePath, "_blank")
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  const brochure = () =>
    generateBrochureDocumentLoading ? (
      <IButton
        margin="0 4rem 0 0"
        hasIconFill
        type="inline-button"
        leadingIcon="bookopen"
        appearance="primaryInline"
        isLoading={generateBrochureDocumentLoading}
      >
        Fetching
      </IButton>
    ) : (
      <IButton
        margin="0 4rem 0 0"
        hasIconFill
        type="inline-button"
        leadingIcon="bookopen"
        appearance="primaryInline"
        onClick={() => generateBrochureDocument()}
      >
        Brochure
      </IButton>
    )

  const demandsAndNeeds = () =>
    generateDemandsAndNeedsDocumentLoading ? (
      <IButton
        margin="0 4rem 0 0"
        hasIconFill
        type="inline-button"
        leadingIcon="bookopen"
        appearance="primaryInline"
        isLoading={generateDemandsAndNeedsDocumentLoading}
      >
        Fetching
      </IButton>
    ) : (
      <IButton
        margin="0 4rem 0 0"
        hasIconFill
        type="inline-button"
        leadingIcon="bookopen"
        appearance="primaryInline"
        onClick={() => generateDemandsAndNeedsFormDocument()}
      >
        Demands & Needs
      </IButton>
    )

  return (
    <>
      {!isLoading && (
        <CardWrapper>
          {hasQuoteExpired && product.expiry_date && (
            <OverlayWrapper>
              <OverlayFlex>
                <P colour="white" margin="10px" fontSize="20px">
                  {`This ${productType} is over 30 days old and has therefore expired – please contact your
                adviser to be re-quoted.`}
                </P>
              </OverlayFlex>
            </OverlayWrapper>
          )}

          <CardHeader>
            <CardHeaderColLeft>
              {isSwitchSave && (
                <div
                  className="tag"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "5px",
                    background: "#197DA4",
                    padding: "5px",
                    "border-radius": "5px",
                    color: "#fff"
                  }}
                >
                  Switch & save up to 10%
                </div>
              )}
              {isMoriPlus && (
                <div
                  className="tag"
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "5px",
                    background: "#197DA4",
                    padding: "5px",
                    "border-radius": "5px",
                    color: "#fff"
                  }}
                >
                  Mori+ Discount Applied
                </div>
              )}

              {product?.provider?.logo_file_url && (
                <CardHeaderLogo
                  src={product?.provider?.logo_file_url}
                  alt={`${get(product, "provider_name", "")} brand logo`}
                  loading="lazy"
                />
              )}
              <CardHeaderColLeftContent>
                <H3 margin="0 0 0.3rem 0">{get(product, "provider_name", "")}</H3>
                <P margin="0" fontSize="1.4rem">
                  {t(get(product, "product_name", ""))}
                </P>
              </CardHeaderColLeftContent>
            </CardHeaderColLeft>

            <CardHeaderColRight>
              <Strong>Premium:</Strong>
              <CardHeaderPriceBreakdown>
                <CardHeaderPriceBreakdownCol>
                  <Label>Monthly</Label>
                  <Strong>£{parseFloat(get(product, "monthly_premium")).toFixed(2)}</Strong>
                </CardHeaderPriceBreakdownCol>
                <CardHeaderPriceBreakdownCol>
                  <Label>Annual</Label>
                  <Strong>£{parseFloat(get(product, "annual_premium")).toFixed(2)}</Strong>
                </CardHeaderPriceBreakdownCol>
              </CardHeaderPriceBreakdown>
            </CardHeaderColRight>
          </CardHeader>
          <CardBody>
            {isQuote ? (
              <CardBodyCol>
                <Label>Quoted on</Label>
                <Strong>
                  {product?.created_at
                    ? `${moment
                        .utc(product?.created_at)
                        .local()
                        .format("DD/MM/YYYY HH:mm")} (quote valid for 30 days from this date)`
                    : ""}
                </Strong>
              </CardBodyCol>
            ) : (
              <CardBodyCol>
                <Label>Start Date</Label>
                <Strong>
                  {product?.start_date
                    ? moment.utc(product?.start_date).local().format("DD/MM/YYYY")
                    : ""}
                </Strong>
              </CardBodyCol>
            )}
            <CardBodyCol>
              <Label>Underwriting</Label>
              <Strong>{`${t(product?.underwriting_style)} ${t(
                product?.underwriting_type
              )}`}</Strong>
            </CardBodyCol>
            <CardBodyCol>
              <Label>Quoted by</Label>
              <Strong>
                {[
                  `${get(product, "sales_agent.first_name", "")} ${get(
                    product,
                    "sales_agent.last_name",
                    ""
                  )}`,
                  get(product, "organisation.name", null)
                ]
                  .filter(n => n)
                  .join(" - ")}
              </Strong>
            </CardBodyCol>
            {isQuote ? (
              <CardBodyCol>
                <Label>Start Date</Label>
                <Strong>
                  {product?.start_date
                    ? moment.utc(product?.start_date).local().format("DD/MM/YYYY")
                    : ""}
                </Strong>
              </CardBodyCol>
            ) : (
              <></>
            )}
            <CardBodyCol>
              <Label>{isQuote ? "Quote" : "Policy"} reference</Label>
              <Strong>{get(product, "reference", "")}</Strong>
            </CardBodyCol>
            <CardBodyCol>
              <ButtonSml
                leadingIcon="call-made"
                hasIconFill
                iconSize="1rem"
                type="inline-button"
                margin="0"
                appearance="primaryInline"
                onClick={() => handleAddInfoClick(product)}
              >
                Additional product details
              </ButtonSml>
            </CardBodyCol>
          </CardBody>
          <CardFooter>
            <IButton
              margin="0 4rem 0 0"
              hasIconFill
              type="inline-button"
              leadingIcon="document"
              appearance="primaryInline"
              onClick={() => handleDocumentsClick(product)}
            >
              Documents
            </IButton>

            {isQuote ? brochure() : demandsAndNeeds()}

            {/* TODO: Messaging functionality out of scope during the time of development */}
            {/* <StyledButton
        margin="0"
        hasIconFill
        type="inline-button"
        leadingIcon="chat"
        appearance="primaryInline"
      >
        Messaging
      </StyledButton> */}
          </CardFooter>
        </CardWrapper>
      )}
    </>
  )
}

ProductCard.defaultProps = {
  handleDocumentsClick: nullFunc,
  handleAddInfoClick: nullFunc,
  isLoading: false,
  productType: "quote"
}

ProductCard.propTypes = {
  product: PropTypes.object,
  handleDocumentsClick: PropTypes.func,
  handleAddInfoClick: PropTypes.func,
  isLoading: PropTypes.bool,
  productType: PropTypes.string
}

export default ProductCard
