import React, { useContext } from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { QuotesContext } from "./quotes.context"

// Components
import DocumentsView from "../../Molecules/DocumentsView"
import AdditionalInfoView from "../../Molecules/AdditionalInfoView"

const QuotesPanel = () => {
  const { viewLoading, selectedQuote: viewData, productType } = useContext(QuotesContext)
  const { setPanelStatus } = useContext(PageContext)

  return (
    <FlyOutPanel
      body={() => <DocumentsView viewData={viewData} isLoading={viewLoading} type="quotes" />}
      wideBody={() => (
        <AdditionalInfoView viewData={viewData} isLoading={viewLoading} productType={productType} />
      )}
      name="quotes_panel"
      onClose={() => setPanelStatus("closed")}
    />
  )
}

export default QuotesPanel
