import React, { useCallback, useContext, useEffect } from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import { PageContext } from "@4cplatform/elements/Organisms"
import { useGet, ApiError, PusherContext } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { isValidJson } from "@4cplatform/elements/Helpers"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/QuoteAdmin"
import reducer from "./quotes.reducer"

const QuotesProvider = ({ children }) => {
  const t = useTranslations()
  const { selfServiceData } = useContext(PageContext)
  const { client: pusher } = useContext(PusherContext)
  // State
  const [
    {
      page,
      perPage,
      selectedQuote,
      sorting,
      total,
      data,
      productType,
      viewData,
      isDisablePrintQuote
    },
    dispatch
  ] = React.useReducer(reducer, {
    page: 1,
    perPage: 10,
    selectedQuote: null,
    sorting: "annual_premium",
    total: null,
    data: [],
    productType: "quote",
    viewData: {},
    isDisablePrintQuote: true
  })

  // Get paginated quotes
  const {
    loading: quotesLoading,
    error: quotesError,
    refetch: quotesRefetch
  } = useGet({
    endpoint: "/quotes",
    query: {
      page,
      limit: perPage,
      order_by: sorting
    },
    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("QUOTES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Get single quote
  const { loading: viewLoading, error: viewError } = useGet({
    endpoint: "/quotes/:slug",
    skip: !selectedQuote || !get(selectedQuote, "slug"),
    params: {
      slug: get(selectedQuote, "slug", "")
    },

    onCompleted: res => {
      const newData = get(res, "data", {})

      dispatch({ type: "UPDATE_VALUE", key: "viewData", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("QUOTE_VIEW_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Subscribe to quote service
  const onQuoteArrived = useCallback(
    res => {
      const quote = isValidJson(get(res, "data")) ? JSON.parse(get(res, "data")) : null
      const newArrivedQuotes = [...data, quote]
      if (quote) {
        dispatch({
          type: "UPDATE_VALUE",
          key: "data",
          value: newArrivedQuotes
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(`encrypted-user.${get(selfServiceData, "id")}`)

      channel.bind("client-portal.quote-pushed", onQuoteArrived)

      return () => pusher.unsubscribe(`encrypted-user.${get(selfServiceData, "id")}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Generate quotes
  const { loading: isPrintQuotesLoading, refetch: rePrintQuote } = useGet({
    endpoint: "/print-pdf-quotes",
    query: {
      order_by: sorting
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      if (filePath) {
        window.open(filePath, "_blank")
      }
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("QUOTATION_DOCUMENTATION_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    },
    skip: isDisablePrintQuote
  })

  const onSort = useCallback(newSorting => {
    dispatch({ type: "UPDATE_VALUE", key: "isDisablePrintQuote", value: true })
    dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting })
  }, [])

  const printQuotes = useCallback(() => {
    dispatch({ type: "UPDATE_VALUE", key: "isDisablePrintQuote", value: false })
    rePrintQuote()
  }, [rePrintQuote])

  return (
    <Provider
      value={{
        quotesLoading,
        quotesError,
        quotesRefetch,
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        pagination: { total, page, perPage },
        selectedQuote,
        onQuoteSelect: quote => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedQuote", value: quote })
        },
        sorting,
        onSort,
        total,
        data,
        productType,
        viewData,
        viewLoading,
        isPrintQuotesLoading,
        isDisablePrintQuote,
        printQuotes
      }}
    >
      {children}
      <ApiError error={quotesError || viewError} />
    </Provider>
  )
}

QuotesProvider.defaultProps = {
  children: null
}

QuotesProvider.propTypes = {
  children: PropTypes.any
}

export default QuotesProvider
