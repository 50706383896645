import { getClientDash } from "./client"

/**
 * This accepts a single parameter of the user's role and returns the appropriate dashboard object.
 * For use in the Navigation subcomponent within the Page Organism and within the Dashboard page.
 * @param role The user's role
 */
export const getUserDashboard = () => getClientDash()

export { getClientNav } from "./client"
