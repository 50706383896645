/**
 * Pages
 */
import {
  Login,
  ForgottenPassword,
  ResetPassword,
  TwoFactorAuth,
  ActivateUser,
  PasswordChangeConfirmation,
  Dashboard,
  MyAccount,
  Forbidden,
  NotFound,
  Quotes,
  Policies
} from "../pages"

/**
 * Page definitions
 *
 * Centralised for ease of management and re-usability
 */
import {
  LOGIN,
  FORGOTTEN_PASSWORD,
  RESET_PASSWORD,
  TWO_FACTOR_AUTH,
  ACTIVATE_USER,
  PASSWORD_CHANGE_CONFIRMATION,
  DASHBOARD,
  MY_ACCOUNT,
  FORBIDDEN,
  NOT_FOUND,
  QUOTES,
  POLICIES
} from "./pages"

const routes = [
  /**
   * Authentication Gate
   */
  {
    isExact: true,
    component: Login,
    isAuthenticatedRoute: false,
    ...LOGIN
  },
  {
    isExact: true,
    component: ForgottenPassword,
    isAuthenticatedRoute: false,
    ...FORGOTTEN_PASSWORD
  },
  {
    isExact: true,
    component: ResetPassword,
    isAuthenticatedRoute: false,
    ...RESET_PASSWORD
  },
  {
    isExact: true,
    component: TwoFactorAuth,
    isAuthenticatedRoute: false,
    ...TWO_FACTOR_AUTH
  },
  {
    isExact: true,
    component: ActivateUser,
    isAuthenticatedRoute: false,
    ...ACTIVATE_USER
  },
  {
    isExact: false,
    component: PasswordChangeConfirmation,
    isAuthenticatedRoute: false,
    ...PASSWORD_CHANGE_CONFIRMATION
  },
  /**
   * Self-service
   */
  {
    isExact: true,
    component: MyAccount,
    isAuthenticatedRoute: true,
    ...MY_ACCOUNT
  },

  /**
   * Policies
   */
  {
    isExact: true,
    isStrict: true,
    component: Policies,
    isAuthenticatedRoute: true,
    ...POLICIES
  },

  /**
   * Quotes
   */
  {
    isExact: true,
    isStrict: true,
    component: Quotes,
    isAuthenticatedRoute: true,
    ...QUOTES
  },
  /**
   * Something Went Wrong
   */
  {
    isExact: true,
    component: Forbidden,
    isAuthenticatedRoute: false,
    ...FORBIDDEN
  },
  /**
   * Dashboard
   */
  {
    isExact: true,
    isStrict: true,
    component: Dashboard,
    isAuthenticatedRoute: true,
    ...DASHBOARD
  },
  {
    component: NotFound,
    isAuthenticatedRoute: false,
    ...NOT_FOUND
  }
]

export default routes
