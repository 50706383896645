import styled, { css } from "styled-components"
import { get } from "lodash"
import { P } from "@4cplatform/elements/Typography"
import { Button } from "@4cplatform/elements/Molecules"
import { getBreakpoint } from "@4cplatform/elements/Helpers"

// Helpers
const CardColPadding = css`
  padding: 1.8rem;
`

export const Strong = styled(P)`
  font-weight: bold;
  margin: 0;
`

export const Label = styled(P)`
  margin: 0;
  color: ${({ theme }) => get(theme, "tints.secondary.darkBlue.t50", "darkblue")};
`

export const ButtonSml = styled(Button)`
  font-size: 1.4rem;
`

export const IButton = styled(Button)`
  padding: 0;
`

export const CardWrapper = styled.article`
  margin: 2rem 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => get(theme, "tints.secondary.darkBlue.t70", "darkblue")};
  position: relative;
`

export const OverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 2;
`
export const OverlayFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => get(theme, "faintGrey", "darkblue")};
  flex-direction: row;
  ${getBreakpoint({ max: "medium" })} {
    flex-direction: column;
  }
`

export const CardHeaderColLeft = styled.div`
  ${CardColPadding}
  display: flex;
  position: relative;
`

export const CardHeaderColRight = styled.div`
  ${CardColPadding}
  background-color: ${({ theme }) => get(theme, "veryFaintGrey", "#ccc")};
  border-radius: 0 4px 4px 0;
  min-width: 25rem;
`

export const CardHeaderLogo = styled.img`
  margin: 0;
  width: 7rem;
  height: 7rem;
`

export const CardHeaderColLeftContent = styled.div`
  margin: 0;
  padding-left: 1rem;
  align-self: center;
`

export const CardHeaderPriceBreakdown = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.6rem;

  ${getBreakpoint({ max: "medium" })} {
    justify-content: space-evenly;
  }
`

export const CardHeaderPriceBreakdownCol = styled.div``

export const CardBody = styled.div`
  ${CardColPadding}
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 1.8rem;
  border-bottom: 1px solid ${({ theme }) => get(theme, "faintGrey", "darkblue")};
`

export const CardBodyCol = styled.div``

export const CardFooter = styled.div`
  ${CardColPadding}
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  ${getBreakpoint({ max: "medium" })} {
    flex-direction: column;
  }
`
