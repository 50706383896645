import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import moment from "moment"
import { LabelWithText } from "@4cplatform/elements/Atoms"
// TODO: FCNG-2759 (https://usaycompare.atlassian.net/browse/FCNG-2759)
// import { Button } from "@4cplatform/elements/Molecules"
import { H2, H4 } from "@4cplatform/elements/Typography"
import { PanelHeader } from "@4cplatform/elements/Molecules/FlyOutPanel"

// Helpers
import { useTranslations } from "@4cplatform/elements/Translations"

// Components
import {
  HeaderContentWrapper,
  HeaderTop,
  HeaderDetailsWrapper,
  HeaderDetailsLeft,
  HeaderDetailsRight,
  HeaderDetails,
  HeaderLogo
} from "./additionalInfoView.styles"

const AdditionalInfoPanelHeader = ({ viewData, isLoading, productType }) => {
  const t = useTranslations()
  const isQuote = productType === "quote"

  return (
    <PanelHeader>
      <H2 appearance="light" margin="2rem 0 1rem" isLoading={isLoading}>
        {`Your ${productType}`}
      </H2>

      <HeaderContentWrapper>
        <HeaderTop>
          {!isLoading && <HeaderLogo src={viewData?.provider?.logo_file_url} loading="lazy" />}

          <H4 padding="0" margin="10px" colour="#fff" isLoading={isLoading}>
            {t(get(viewData, "product_name", ""))}
          </H4>
        </HeaderTop>

        <HeaderDetailsWrapper>
          <HeaderDetailsLeft>
            {isQuote && (
              <HeaderDetails>
                <LabelWithText
                  label="Quoted on:"
                  content={
                    viewData?.created_at
                      ? `${moment
                          .utc(viewData?.created_at)
                          .local()
                          .format("DD/MM/YYYY HH:mm")} (quote valid for 30 days from this date)`
                      : ""
                  }
                  appearance="light"
                  indent="0"
                  isLoading={isLoading}
                />
              </HeaderDetails>
            )}
            <HeaderDetails>
              <LabelWithText
                label="Started on:"
                content={
                  viewData?.start_date
                    ? moment.utc(viewData?.start_date).local().format("DD/MM/YYYY")
                    : ""
                }
                appearance="light"
                indent="0"
                isLoading={isLoading}
              />
            </HeaderDetails>
            <HeaderDetails>
              <LabelWithText
                label=" Quoted by:"
                content={[
                  `${get(viewData, "sales_agent.first_name", "")} ${get(
                    viewData,
                    "sales_agent.last_name",
                    ""
                  )}`,
                  get(viewData, "organisation.name", null)
                ]
                  .filter(n => n)
                  .join(" - ")}
                appearance="light"
                indent="0"
                isLoading={isLoading}
              />
            </HeaderDetails>
          </HeaderDetailsLeft>

          <HeaderDetailsRight>
            <HeaderDetails>
              <LabelWithText
                label="Underwriting:"
                content={`${t(viewData?.underwriting_style)} ${t(viewData?.underwriting_type)} `}
                appearance="light"
                indent="0"
                isLoading={isLoading}
              />
            </HeaderDetails>
            <HeaderDetails>
              <LabelWithText
                label="Quote reference:"
                content={get(viewData, "reference", "")}
                appearance="light"
                indent="0"
                isLoading={isLoading}
              />
            </HeaderDetails>
          </HeaderDetailsRight>
        </HeaderDetailsWrapper>

        {/*
        TODO: FCNG-2759 (https://usaycompare.atlassian.net/browse/FCNG-2759)
        {!isLoading && (
          <Button
            appearance="whiteGhost"
            trailingIcon="printer"
            onClick={() => window.print()}
            isDisabled={isLoading}
            name="print_quote"
            isLoading={isLoading}
          >
            Print
          </Button>
        )} */}
      </HeaderContentWrapper>
    </PanelHeader>
  )
}

AdditionalInfoPanelHeader.defaultProps = {
  viewData: {},
  isLoading: false,
  productType: "quote"
}

AdditionalInfoPanelHeader.propTypes = {
  viewData: PropTypes.object,
  isLoading: PropTypes.bool,
  productType: PropTypes.string
}

export default AdditionalInfoPanelHeader
