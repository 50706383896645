import styled, { css } from "styled-components"
import { get } from "lodash"

export const ALinkDummy = styled.span`
  font-size: 1.6rem;
  display: flex;
  cursor: not-allowed;
  color: ${({ theme, colour }) =>
    colour ? get(theme, colour, "blue") : get(theme, "blue", "blue")};

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        opacity: 0.7;
      `
    }
  }}
`
