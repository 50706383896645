/* istanbul ignore file */
import React from "react"
import { Helmet } from "react-helmet-async"
import { Gate } from "@4cplatform/elements/Auth"

const ActivateUser = () => (
  <>
    <Helmet>
      <title>Activate User</title>
    </Helmet>
    <Gate type="activate-user" />
  </>
)

export default ActivateUser
