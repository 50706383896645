import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"
import { H1, P } from "@4cplatform/elements/Typography"

// Components
import Details from "./pages/Details"

const MyAccount = () => (
  <Container isPageContainer width="80%">
    <Helmet>
      <title>My Account</title>
    </Helmet>
    <H1 margin="3rem 0 3rem">My Account</H1>
    <P>View and manage your account details and settings.</P>
    <Details />
  </Container>
)

export default MyAccount
