import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"
import { H1 } from "@4cplatform/elements/Typography"

// Components
import { QuoteAdmin } from "../../UI/Templates"
import QuotesProvider from "./context/quotes.provider"
import { QuotesPanel } from "../../UI/Templates/QuoteAdmin"

const Quotes = () => (
  <>
    <Helmet>
      <title>Your quotes</title>
    </Helmet>
    <QuotesProvider>
      <Container isPageContainer width="80%">
        <H1 margin="3rem 0 3rem">Your quotes</H1>
        <QuoteAdmin />
      </Container>
      <QuotesPanel />
    </QuotesProvider>
  </>
)

export default Quotes
