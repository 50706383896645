import React, { useContext, useReducer } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import { usePost } from "@4cplatform/elements/Api"
import { addAlert } from "@4cplatform/elements/Alerts"
import { useTranslations } from "@4cplatform/elements/Translations"
import { PageContext } from "@4cplatform/elements/Organisms"
import { Provider } from "@4cplatform/elements/Templates/MyAccountDetails"

// Helpers
import reducer from "./details.reducer"

const SelfServiceProvider = ({ children }) => {
  const { selfServiceData, selfServiceLoading } = useContext(PageContext)

  const t = useTranslations()

  // Manage state
  const [{ changePasswordOpen }, dispatch] = useReducer(reducer, {
    changePasswordOpen: false
  })

  // Change password
  const [onChangePassword, { loading: changePasswordLoading, error: changePasswordError }] =
    usePost({
      endpoint: "/auth/self-service/change-password",
      onCompleted: () => {
        dispatch({ type: "UPDATE_VALUE", key: "changePasswordOpen", value: false })
        addAlert({
          type: "success",
          message: t("CHANGE_PASSWORD_SUCCESS")
        })
      },
      onError: ({ validation }) => {
        if (get(validation, "password", []).includes("PASSWORD_ALREADY_USED")) {
          addAlert({
            type: "error",
            message: t("PASSWORD_ALREADY_USED")
          })
        } else {
          addAlert({
            type: "error",
            message: t("CHANGE_PASSWORD_ERROR")
          })
        }
      }
    })

  return (
    <Provider
      value={{
        user: selfServiceData,
        userLoading: selfServiceLoading,
        changePasswordOpen,
        toggleChangePassword: value =>
          dispatch({ type: "UPDATE_VALUE", key: "changePasswordOpen", value }),
        onChangePassword,
        changePasswordLoading,
        changePasswordError
      }}
    >
      {children}
    </Provider>
  )
}

SelfServiceProvider.defaultProps = {
  children: null
}

SelfServiceProvider.propTypes = {
  children: PropTypes.any
}

export default SelfServiceProvider
