import React, { Fragment } from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import { v4 as uuid } from "uuid"
import { H2, A } from "@4cplatform/elements/Typography"
import { Skeleton, PanelHeader, PanelBody } from "@4cplatform/elements/Molecules"
import { IconWithText } from "@4cplatform/elements/Atoms"
import { useTranslations } from "@4cplatform/elements/Translations"
import { usePost } from "@4cplatform/elements/Api/useMutation"
import { addAlert } from "@4cplatform/elements/Alerts"
import { colours } from "@4cplatform/elements/Helpers"

import { Timeline } from "../../Organisms"
import { ALinkDummy } from "./documentsView.styles"

const DocumentsView = ({ isLoading, viewData, type }) => {
  const t = useTranslations()

  const formatVersionsAsEvents = ({ versions, current, onClick }) =>
    versions.map(version => {
      const { id, version_number: num, file_name: name, created_at: date } = version
      const isCurrent = num === get(current, "version_number")
      const fileName = name.split("/").pop()

      return {
        key: uuid(),
        id,
        content: `${fileName} v${num}`,
        date,
        iconColour: isCurrent ? colours.green : colours.darkBlue,
        icon: isCurrent ? "check" : "plus",
        onClick
      }
    })

  const onVersionClick = documentlink => window.open(documentlink, "_blank")

  // Generate Application Form
  const [generateApplicationFormDocument, { loading: generateApplicationFormDocumentLoading }] =
    usePost({
      endpoint: `/${type}/:reference/generate-document/application_form`,
      params: {
        reference: type === "quotes" ? get(viewData, "id", "") : get(viewData, "slug", "")
      },
      onCompleted: res => {
        const filePath = get(res, "data.path", "")
        window.open(filePath, "_blank")
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  // Generate Brochure
  const [generateBrochureDocument, { loading: generateBrochureDocumentLoading }] = usePost({
    endpoint: `/${type}/:reference/generate-document/brochure`,
    params: {
      reference: type === "quotes" ? get(viewData, "id", "") : get(viewData, "slug", "")
    },
    onCompleted: res => {
      const filePath = get(res, "data.path", "")
      window.open(filePath, "_blank")
    },
    onError: () => {
      addAlert({
        type: "error",
        message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
        dismissible: true,
        timeout: 5
      })
    }
  })

  // Generate Demands & Neeeds
  const [generateDemandsAndNeedsFormDocument, { loading: generateDemandsAndNeedsDocumentLoading }] =
    usePost({
      endpoint: `/${type}/:reference/generate-document/demands_and_needs`,
      params: {
        reference: type === "quotes" ? get(viewData, "id", "") : get(viewData, "slug", "")
      },
      onCompleted: res => {
        const filePath = get(res, "data.path", "")
        window.open(filePath, "_blank")
      },
      onError: () => {
        addAlert({
          type: "error",
          message: t("ONBOARDED_DOCUMENTATION_GENERATE_ERROR"),
          dismissible: true,
          timeout: 5
        })
      }
    })

  const applicationForm = () =>
    generateApplicationFormDocumentLoading ? (
      <ALinkDummy margin="0" colour="white" isLoading={generateApplicationFormDocumentLoading}>
        Fetching
      </ALinkDummy>
    ) : (
      <A
        appearance="light"
        fontSize="1.6rem"
        onClick={() => generateApplicationFormDocument()}
        isLoading={generateApplicationFormDocumentLoading}
      >
        Application Form
      </A>
    )

  const brochure = () =>
    generateBrochureDocumentLoading ? (
      <ALinkDummy margin="0" colour="white" isLoading={generateBrochureDocumentLoading}>
        Fetching
      </ALinkDummy>
    ) : (
      <A
        appearance="light"
        fontSize="1.6rem"
        onClick={() => generateBrochureDocument()}
        isLoading={generateBrochureDocumentLoading}
      >
        Brochure
      </A>
    )

  const demandsAndNeeds = () =>
    generateDemandsAndNeedsDocumentLoading ? (
      <ALinkDummy margin="0" colour="white" isLoading={generateDemandsAndNeedsDocumentLoading}>
        Fetching
      </ALinkDummy>
    ) : (
      <A
        appearance="light"
        fontSize="1.6rem"
        onClick={() => generateDemandsAndNeedsFormDocument()}
        isLoading={generateDemandsAndNeedsDocumentLoading}
      >
        Demands & Needs
      </A>
    )

  return (
    <>
      <PanelHeader>
        <H2 appearance="light" margin="2rem 0 1rem">
          Documents
        </H2>
      </PanelHeader>

      <PanelBody>
        {isLoading && <Skeleton count={1} />}

        {viewData?.inherited_documents &&
          viewData?.inherited_documents.map(document => {
            const versions = get(document, "document_versions", [])
            const current = get(document, "current_active_version")
            const documentLink = get(current, "document_links", "")
            return (
              <Fragment key={uuid()}>
                <IconWithText
                  key={uuid()}
                  appearance="light"
                  icon="filedocument"
                  isLoading={isLoading}
                  margin="0.8rem 0"
                >
                  <A appearance="light" fontSize="1.6rem" decoration="none">
                    {document.name}
                  </A>
                </IconWithText>
                <Timeline
                  key={uuid()}
                  margin="0.8rem 1rem 0"
                  events={formatVersionsAsEvents({
                    versions,
                    current,
                    onClick: () => onVersionClick(documentLink)
                  })}
                />
              </Fragment>
            )
          })}

        {viewData?.provider.name !== "VitalityHealth" && (
          <IconWithText
            key={uuid()}
            appearance="light"
            icon="filedocument"
            isLoading={isLoading}
            margin="0.8rem 0"
          >
            {applicationForm()}
          </IconWithText>
        )}

        <IconWithText
          key={uuid()}
          appearance="light"
          icon="filedocument"
          isLoading={isLoading}
          margin="0.8rem 0"
        >
          {brochure()}
        </IconWithText>

        <IconWithText
          key={uuid()}
          appearance="light"
          icon="filedocument"
          isLoading={isLoading}
          margin="0.8rem 0"
        >
          {demandsAndNeeds()}
        </IconWithText>

        {viewData?.ipid_link && (
          <IconWithText
            key={uuid()}
            appearance="light"
            icon="filedocument"
            isLoading={isLoading}
            margin="0.8rem 0"
          >
            <A appearance="light" fontSize="1.6rem" href={viewData.ipid_link} target="_blank">
              IPID
            </A>
          </IconWithText>
        )}

        {viewData?.hospital_list_link && (
          <IconWithText
            key={uuid()}
            appearance="light"
            icon="filedocument"
            isLoading={isLoading}
            margin="0.8rem 0"
          >
            <A
              appearance="light"
              fontSize="1.6rem"
              href={viewData.hospital_list_link}
              target="_blank"
            >
              Hospital List
            </A>
          </IconWithText>
        )}

        {viewData?.third_party_payer_link && (
          <IconWithText
            key={uuid()}
            appearance="light"
            icon="filedocument"
            isLoading={isLoading}
            margin="0.8rem 0"
          >
            <A
              appearance="light"
              fontSize="1.6rem"
              href={viewData.third_party_payer_link}
              target="_blank"
            >
              Third Party Payer
            </A>
          </IconWithText>
        )}

        {viewData?.company_third_party_payer_files &&
          viewData?.company_third_party_payer_files.map(document => (
            <IconWithText
              key={uuid()}
              appearance="light"
              icon="filedocument"
              isLoading={isLoading}
              margin="0.8rem 0"
            >
              <A appearance="light" fontSize="1.6rem" href={document.url} target="_blank">
                {document.file_name}
              </A>
            </IconWithText>
          ))}
      </PanelBody>
    </>
  )
}

export default DocumentsView

DocumentsView.propTypes = {
  viewData: PropTypes.object,
  isLoading: PropTypes.bool,
  type: PropTypes.string
}
