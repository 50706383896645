import React from "react"
import { Skeleton } from "@4cplatform/elements/Molecules"
import {
  CardWrapper,
  CardHeader,
  CardHeaderColLeft,
  CardHeaderColLeftContent,
  CardHeaderColRight,
  CardHeaderPriceBreakdown,
  CardHeaderPriceBreakdownCol,
  CardBody,
  CardBodyCol,
  CardFooter
} from "../../Molecules/ProductCard/productCard.styles"

const ProductCardSkeleton = () => (
  <CardWrapper>
    <CardHeader>
      <CardHeaderColLeft>
        <Skeleton />
        <CardHeaderColLeftContent>
          <Skeleton />
        </CardHeaderColLeftContent>
      </CardHeaderColLeft>

      <CardHeaderColRight>
        <Skeleton />
        <CardHeaderPriceBreakdown>
          <CardHeaderPriceBreakdownCol>
            <Skeleton />
          </CardHeaderPriceBreakdownCol>
        </CardHeaderPriceBreakdown>
      </CardHeaderColRight>
    </CardHeader>
    <CardBody>
      <CardBodyCol />
    </CardBody>
    <CardFooter>
      <Skeleton />
    </CardFooter>
  </CardWrapper>
)

export default ProductCardSkeleton
