import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { colours } from "@4cplatform/elements/Helpers"
import { Icon } from "@4cplatform/elements/Atoms"
import { v4 as uuid } from "uuid"

// Component
import { EventWrapper, IconWrapper, Text } from "./timeline.styles"
import TimelineButton from "./timeline.event.button"

const Event = ({
  content,
  icon,
  colour,
  iconColour,
  date,
  isLast,
  onClick,
  buttonIcon,
  name,
  documentLink,
  index
}) => (
  <EventWrapper
    key={uuid()}
    isLast={isLast}
    data-testid={`${name}-timeline-event_wrapper_${index}`}
  >
    <IconWrapper iconColour={iconColour} data-testid={`${name}-timeline-icon_wrapper_${index}`}>
      <Icon icon={icon} colour={colour} size="1.6rem" />
    </IconWrapper>
    <TimelineButton
      onClick={onClick}
      documentLink={documentLink}
      buttonIcon={buttonIcon}
      name={name}
      index={index}
    >
      {content}
    </TimelineButton>
    <Text data-testid={`${name}-timeline-date_${index}`}>
      {moment(date, "YYYY-MM-DDTHH:mmZ").format("DD/MM/YY HH:mm")}
    </Text>
  </EventWrapper>
)

Event.defaultProps = {
  icon: "plus",
  colour: colours.white,
  iconColour: colours.darkBlue,
  isLast: false,
  onClick: () => {},
  buttonIcon: null
}

Event.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
  colour: PropTypes.string,
  iconColour: PropTypes.string,
  date: PropTypes.string,
  isLast: PropTypes.bool,
  onClick: PropTypes.func,
  buttonIcon: PropTypes.string,
  name: PropTypes.string.isRequired,
  documentLink: PropTypes.string,
  index: PropTypes.number.isRequired
}

export default Event
