import React from "react"
import { Helmet } from "react-helmet-async"
import { Dashboard as Dash } from "@4cplatform/elements/Templates"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { getUserDashboard } from "../../UI/Helpers"

const Dashboard = () => {
  const { selfServiceLoading } = React.useContext(PageContext)

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Dash
        dashboard={getUserDashboard()}
        title="Welcome to your Client Portal"
        subtitle="Below are links to all Policies and Quotes associated with your account. Click on each section to find out more."
        isLoading={selfServiceLoading}
      />
    </>
  )
}

export default Dashboard
