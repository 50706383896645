import styled from "styled-components"

export const HeaderContentWrapper = styled.div`
  margin-bottom: 4px;
`

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

export const HeaderLogo = styled.img`
  margin: 0;
  width: 7rem;
  height: 7rem;
`

export const HeaderDetailsWrapper = styled.div`
  display: flex;
`

export const HeaderDetailsLeft = styled.div`
  flex-basis: 30%;
`

export const HeaderDetailsRight = styled.div`
  flex-basis: 30%;
`

export const HeaderDetails = styled.div`
  margin: 15px 0;
`

export const ProductDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
`

export const ProductOptionWrapper = styled.div`
  margin: 10px 0;
`
