import React, { useContext } from "react"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { QuotesContext } from "./quotes.context"

// Components
import { ProductList } from "../../Organisms"

const QuoteAdmin = () => {
  const { setPanelStatus } = useContext(PageContext)
  const {
    data,
    onQuoteSelect,
    sorting,
    onSort,
    pagination,
    setPage,
    setPerPage,
    quotesLoading,
    productType,
    isPrintQuotesLoading,
    printQuotes
  } = useContext(QuotesContext)

  return (
    <ProductList
      products={data}
      onDocumentsClick={quote => {
        onQuoteSelect(quote)
        setPanelStatus("open")
      }}
      onAddInfoClick={quote => {
        onQuoteSelect(quote)
        setPanelStatus("wide")
      }}
      sorting={sorting}
      onSort={onSort}
      pagination={pagination}
      changePage={e => setPage(e)}
      changePerPage={setPerPage}
      isLoading={quotesLoading}
      productType={productType}
      isPrintable
      isQuote
      isPrintQuotesLoading={isPrintQuotesLoading}
      printQuotes={printQuotes}
    />
  )
}

export default QuoteAdmin
