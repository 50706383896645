import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "@4cplatform/elements/Atoms"
import { H1 } from "@4cplatform/elements/Typography"

// Components
import PoliciesProvider from "./context/policies.provider"
import PoliciesTemplate from "../../UI/Templates/PolicyAdmin/policies"
import { PoliciesPanel } from "../../UI/Templates/PolicyAdmin"

const Policies = () => (
  <>
    <Helmet>
      <title>Your policies</title>
    </Helmet>

    <PoliciesProvider>
      <Container isPageContainer width="80%">
        <H1 margin="3rem 0 3rem">Your policies</H1>
        <PoliciesTemplate />
      </Container>
      <PoliciesPanel />
    </PoliciesProvider>
  </>
)

export default Policies
