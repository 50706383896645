import React from "react"
import PropTypes from "prop-types"
import { LabelWithText } from "@4cplatform/elements/Atoms"

// Components
import { ProductOptionWrapper } from "./additionalInfoView.styles"

const ProductOption = ({ label, value, isLoading }) => (
  <ProductOptionWrapper>
    <LabelWithText
      label={label}
      content={value}
      appearance="light"
      margin="0"
      indent="0"
      isLoading={isLoading}
    />
  </ProductOptionWrapper>
)

ProductOption.defaultProps = {
  label: "",
  value: "",
  isLoading: false
}

ProductOption.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isLoading: PropTypes.bool
}

export default ProductOption
