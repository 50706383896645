/**
 * Authentication Gate
 */
export const LOGIN = {
  path: "/login"
}

export const FORGOTTEN_PASSWORD = {
  path: "/forgotten-password"
}

export const RESET_PASSWORD = {
  path: "/reset-password"
}

export const TWO_FACTOR_AUTH = {
  path: "/two-factor-authentication"
}

export const ACTIVATE_USER = {
  path: "/activate-user"
}

export const PASSWORD_CHANGE_CONFIRMATION = {
  path: "/password-change-confirmation"
}
/**
 * Dashboard
 */
export const DASHBOARD = {
  path: "/"
}

/**
 * Self-service
 */
export const QUOTES = {
  path: "/quotes"
}

/**
 * Self-service
 */
export const POLICIES = {
  path: "/policies"
}

/**
 * Self-service
 */
export const MY_ACCOUNT = {
  path: "/my-account"
}

/**
 * Something Went Wrong
 */
export const FORBIDDEN = {
  path: "/403"
}

export const NOT_FOUND = {
  path: "*"
}
