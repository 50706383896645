import React, { useContext } from "react"
import { FlyOutPanel } from "@4cplatform/elements/Molecules"
import { PageContext } from "@4cplatform/elements/Organisms"

// Helpers
import { PoliciesContext } from "./policies.context"

// Components
import DocumentsView from "../../Molecules/DocumentsView"
import AdditionalInfoView from "../../Molecules/AdditionalInfoView"

const PoliciesPanel = () => {
  const { viewLoading, selectedPolicy: viewData, productType } = useContext(PoliciesContext)
  const { setPanelStatus } = useContext(PageContext)

  return (
    <FlyOutPanel
      body={() => <DocumentsView viewData={viewData} isLoading={viewLoading} type="policies" />}
      wideBody={() => (
        <AdditionalInfoView viewData={viewData} isLoading={viewLoading} productType={productType} />
      )}
      name="policies_panel"
      onClose={() => setPanelStatus("closed")}
    />
  )
}

export default PoliciesPanel
