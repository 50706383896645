import React from "react"
import { HelmetProvider, Helmet } from "react-helmet-async"
import { Router, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import Theme from "@4cplatform/elements/Global/Theme"
import Normalise from "@4cplatform/elements/Global/Normalise"
import GlobalStyles from "@4cplatform/elements/Global/GlobalStyles"
import { AuthProvider, AuthRoute } from "@4cplatform/elements/Auth"
import { TranslationsProvider } from "@4cplatform/elements/Translations"
import { ConfigProvider } from "@4cplatform/elements/Config"
import { Page } from "@4cplatform/elements/Organisms"
import { PusherProvider } from "@4cplatform/elements/Api"
import { StyledToastifyContainer } from "@4cplatform/elements/Alerts"

// Helpers
import CONFIG, { pusherConfig } from "./config"
import routes from "./config/routes"
import { getClientNav } from "./UI/Helpers"

const App = () => (
  <ConfigProvider config={CONFIG}>
    <HelmetProvider>
      <TranslationsProvider>
        <Theme>
          <Normalise />
          <GlobalStyles />
          <Helmet titleTemplate="%s | Client Portal" />
          <Router history={createBrowserHistory()}>
            <AuthProvider>
              <PusherProvider {...pusherConfig}>
                <StyledToastifyContainer />
                <Page mode="client" dashboardHelper={getClientNav}>
                  <Switch>
                    {routes.map(route => (
                      <AuthRoute key={route.path} {...route} />
                    ))}
                  </Switch>
                </Page>
              </PusherProvider>
            </AuthProvider>
          </Router>
        </Theme>
      </TranslationsProvider>
    </HelmetProvider>
  </ConfigProvider>
)

export default App
