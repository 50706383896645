import React, { useCallback, useContext, useEffect } from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import { PageContext } from "@4cplatform/elements/Organisms"

import { useGet, ApiError, PusherContext } from "@4cplatform/elements/Api"

import { useTranslations } from "@4cplatform/elements/Translations"
import { isValidJson } from "@4cplatform/elements/Helpers"

// Components
import { addAlert } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/PolicyAdmin"
import reducer from "./policies.reducer"

const PoliciesProvider = ({ children }) => {
  const t = useTranslations()
  const { selfServiceData } = useContext(PageContext)

  const { client: pusher } = useContext(PusherContext)

  // State
  const [{ page, perPage, selectedPolicy, sorting, total, data, productType, viewData }, dispatch] =
    React.useReducer(reducer, {
      page: 1,
      perPage: 10,
      selectedPolicy: null,
      sorting: "created_at_desc",
      total: null,
      data: [],
      productType: "policy",
      viewData: {}
    })

  // Get paginated policies
  const {
    loading: policiesLoading,
    error: policiesError,
    refetch: policiesRefetch
  } = useGet({
    endpoint: "/policies",
    query: {
      page,
      limit: perPage,
      order_by: sorting
    },

    onCompleted: res => {
      const newTotal = get(res, "pagination.totalItems")
      const newData = get(res, "data", [])
      dispatch({ type: "FETCH_COMPLETE", total: newTotal, data: newData })
    },
    onError: () => {
      addAlert({
        message: t("POLICIES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const onPolicyArrived = useCallback(
    res => {
      const policy = isValidJson(get(res, "data")) ? JSON.parse(get(res, "data")) : {}
      const newArrivedPolicies = [...data, policy]
      if (policy) {
        dispatch({
          type: "UPDATE_VALUE",
          key: "data",
          value: newArrivedPolicies
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(`encrypted-user.${get(selfServiceData, "id")}`)

      channel.bind("client-portal.quote-pushed", onPolicyArrived)

      return () => pusher.unsubscribe(`encrypted-user.${get(selfServiceData, "id")}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Provider
      value={{
        policiesLoading,
        policiesError,
        policiesRefetch,
        page,
        setPage: val => dispatch({ type: "UPDATE_VALUE", key: "page", value: val }),
        perPage,
        setPerPage: val => dispatch({ type: "UPDATE_VALUE", key: "perPage", value: val }),
        pagination: { total, page, perPage },
        selectedPolicy,
        onPolicySelect: policy => {
          dispatch({ type: "UPDATE_VALUE", key: "selectedPolicy", value: policy })
        },
        sorting,
        onSort: newSorting => dispatch({ type: "UPDATE_VALUE", key: "sorting", value: newSorting }),
        total,
        data,
        productType,
        viewData
      }}
    >
      {children}
      <ApiError error={policiesError} />
    </Provider>
  )
}

PoliciesProvider.defaultProps = {
  children: null
}

PoliciesProvider.propTypes = {
  children: PropTypes.any
}

export default PoliciesProvider
