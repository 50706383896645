import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { v4 as uuid } from "uuid"
import { nullFunc, colours } from "@4cplatform/elements/Helpers"
import { Select } from "@4cplatform/elements/Forms"
import { Pagination, Button } from "@4cplatform/elements/Molecules"
import { P } from "@4cplatform/elements/Typography"
import { Icon } from "@4cplatform/elements/Atoms"

// Components
import { ProductCard } from "../../Molecules"
import ProductListSkeleton from "./productList.skeleton"
import { StyledActionsWrapper, PaginationWrapper } from "./productList.styles"

const options = [
  { name: "Creation date (most recent)", value: "created_at_desc" },
  { name: "Creation date (oldest)", value: "created_at" },
  { name: "Price (highest)", value: "annual_premium_desc" },
  { name: "Price (lowest)", value: "annual_premium" }
]

const ProductList = ({
  products,
  onDocumentsClick,
  onAddInfoClick,
  sorting,
  onSort,
  pagination,
  changePage,
  changePerPage,
  isLoading,
  productType,
  isPrintable,
  isPrintQuotesLoading,
  printQuotes
}) => (
  <>
    <StyledActionsWrapper>
      <Select
        name="sort_products"
        label="Sort by"
        labelWidth="auto"
        isHorizontal
        onChange={val => onSort(val)}
        margin="0 1rem 0 0"
        value={sorting || ""}
      >
        {options?.map(item => (
          <option key={uuid()} value={item.value}>
            {item.name}
          </option>
        ))}
      </Select>

      {isPrintable && (
        <Button
          onClick={printQuotes}
          name="print_quote"
          colour="white"
          isLoading={isPrintQuotesLoading}
        >
          Print Quotes
          <Icon icon="printer" colour={colours.white} margin="0 0.4rem 0 1rem" />
        </Button>
      )}
    </StyledActionsWrapper>

    {!isLoading &&
      !isEmpty(products) &&
      products?.map(product => (
        <ProductCard
          key={`key-product_card_${product.id}`}
          product={product}
          handleDocumentsClick={onDocumentsClick}
          handleAddInfoClick={onAddInfoClick}
          isLoading={isLoading}
          productType={productType}
        />
      ))}

    {!isLoading && isEmpty(products) && (
      <P margin="2rem 0 2rem" align="center" fontSize="1.2rem">
        No data is available for display
      </P>
    )}

    {isLoading && <ProductListSkeleton />}

    <PaginationWrapper>
      <Pagination
        {...pagination}
        pagesToShow={7}
        changePage={changePage}
        changePerPage={changePerPage}
        hasPerPage
        appearance="dark"
        name={productType === "quote" ? "Quotes" : "Policies"}
      />
    </PaginationWrapper>
  </>
)

ProductList.defaultProps = {
  onDocumentsClick: nullFunc,
  onAddInfoClick: nullFunc,
  isLoading: false,
  isPrintQuotesLoading: false,
  isPrintable: false,
  changePage: () => {},
  printQuotes: () => {}
}

ProductList.propTypes = {
  products: PropTypes.array,
  sorting: PropTypes.string,
  onDocumentsClick: PropTypes.func,
  onAddInfoClick: PropTypes.func,
  onSort: PropTypes.func,
  pagination: PropTypes.object,
  changePage: PropTypes.func,
  changePerPage: PropTypes.func,
  isLoading: PropTypes.bool,
  isPrintQuotesLoading: PropTypes.bool,
  isPrintable: PropTypes.bool,
  productType: PropTypes.string,
  printQuotes: PropTypes.func
}

export default ProductList
